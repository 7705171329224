<template>
  <footer class="mb-5">
    <div class="container">
      <div class="columns is-vcentered mb-0">
        <div class="column is-3 is-offset-1 footer-image">
          <img src="/allmake_footer.svg">
        </div>
        <div class="column is-3 is-offset-2 has-text-left has-text-centered-mobile">
          <h1 class="title is-3 mb-1 footer-title">Nawigacja</h1>
          <ul class="navigation">
            <li><a>Strona główna</a></li>
            <li><a>Zrealizowane usługi</a></li>
            <li><a>Zaufali nam</a></li>
            <li><a>Portfolio</a></li>
            <li><a>Kontakt</a></li>
          </ul>
        </div>
        <div class="column has-text-left has-text-centered-mobile" style="flex: none;">
          <h1 class="title is-3 mb-1 footer-title">Social Media</h1>
          <div class="icons-container" style="gap: 10px;">
            <font-awesome-icon icon="fa-brands fa-tiktok" size="2x" class="icon-color" />
            <font-awesome-icon icon="fa-brands fa-youtube" size="2x" class="icon-color" />
            <font-awesome-icon icon="fa-brands fa-facebook" size="2x" class="icon-color" />
            <font-awesome-icon icon="fa-brands fa-instagram" size="2x" class="icon-color" />
          </div>
        </div>
      </div>
      <hr class="footer-line">
      <div class="footer-bottom">
        <h1 class="title is-6 mb-1">© allmake.pl 2022</h1>
        <h1 class="title is-6 ml-auto-desktop">Wszelkie prawa zastrzeżone.</h1>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.navigation > li {
  padding-bottom: 5px;
}

.navigation > li > a {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  transition: color .5s;
}

.navigation > li:hover > a {
  color: #08adf7;
}

.footer-title {
  color: #1a1a1a;
  font-weight: 700;
}

.icon-color {
  color: #1a1a1a;
  cursor: pointer;
  transition: color .5s;
}

.icon-color:hover {
  color: #08adf7;
}

.footer-line {
  border: 1px solid #000;
  margin-bottom: 10px;
  margin-top: 10px;
}

.icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bottom {
  display: flex;
}

.mailText {
  font-size: 2.5rem;
}

@media screen and (max-width: 700px) {
  .footer-line {
    margin-left: 15px;
    margin-right: 15px;
  }

  .footer-bottom {
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  .footer-image > img {
    max-width: 75%;
  }
}
</style>
