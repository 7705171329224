<template>
  <div class="container mt-6 mobile-padding-nav" style="width: 100%">
    <div class="nav">
      <div class="logo">
        <img src="/images/logo.png" />
      </div>
      <div :class="['nav-items', {'nav-active': active}]">
        <ul>
          <li class="nav-text"><a>Strona Główna</a></li>
          <li class="nav-text"><a>Czym się zajmujemy</a></li>
          <li class="nav-text"><a>Zaufali nam</a></li>
          <li class="nav-text"><a>Portfolio</a></li>
          <li><a class="nav-button">Kontakt</a></li>
        </ul>
      </div>
      <div class="break"></div>
      <div class="lines" @click="active = !active">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar"
}
</script>

<style scoped>
.nav {
  display: flex;
  align-items: center;
  width: 100%;
  flex-flow: row wrap;
}

.nav-text {
  margin-left: 30px;
  margin-right: 30px;
}

.nav-text > a {
  color: #1a1a1a;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.5s;
}

.nav-text > a:hover {
  color: #08adf7;
}

.nav-button {
  padding: 4px 20px;

  background-color: #000;
  color: #fff;
  border-radius: 16px;
  font-weight: 700;
  font-size: 1rem;
  transition: background-color 0.5s;
}

.nav-button:hover {
  color: #fff;
  background-color: #08adf7;
}

@media screen and (min-width: 900px) {
  .nav-items > ul li {
    display: inline;
  }

  .nav-items {
    margin-left: auto;
  }
}

@media screen and (max-width: 1250px) {
  .nav-text {
    margin-left: 15px;
    margin-right: 15px;
  }

  .nav-button {
    margin-left: 15px;
  }
}

@media screen and (max-width: 900px) {
  .mobile-padding-nav {
    padding-left: 24px;
    padding-right: 24px;
  }

  .nav-items {
    background-color: white;
    width: 100%;
    display: none;
    transition: top .2s cubic-bezier(.22,.61,.36,1),opacity .2s cubic-bezier(.22,.61,.36,1),transform .2s cubic-bezier(.22,.61,.36,1);
    padding: 15px;
  }

  .nav-active {
    display: block;
    top: 200px
  }

  .nav-items > li {
    display: block;
    width: 100%;
  }

  .nav-text {
    padding: 5px;
  }

  .nav {
    align-items: center;
    justify-content: center;
    flex-flow: wrap row;
    width: 100%;
    padding: 0 15px;
  }

  .break {
    order: 3;
    width: 100%;
  }

  .lines {
    display: flex;
    gap: 3.5px;
    flex-direction: column;
    flex: 1;
    order: 2;
    cursor: pointer;
  }

  .line {
    width: 35px;
    height: 6px;
    background-color: #000;
    border-radius: 16px;
    margin-left: auto;
  }

  .logo {
    flex: 1;
    order: 1;
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
  }

  .logo > img {
    height: 32px;
  }

}

@media screen and (max-width: 320px) {
  .mobile-padding-nav {
    padding-left: 4px;
    padding-right: 4px;
  }
}

</style>
