<template>
  <div class="zaufaliContainer">
    <div class="zaufaliBox">
      <div class="containerCarousel">
        <div class="left-shadow"></div>
        <div style="transform: translate3d(0px, 0px, 0px);" id="carousel" class="carouselController">
          <div class="company" v-for="company in companies" :key="company">
            <img :src="company.logo">
            <span>{{ company.title }}</span>
          </div>
        </div>
        <div class="right-shadow"></div>
      </div>
      <div class="zaufaliBox-control">
        <span class="iconLeft" @click="move('l')">
          <font-awesome-icon icon="fa-solid fa-angle-left" />
        </span>
        <span class="iconRight" @click="move('r')">
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    companies: Array
  }, methods: {
    getMatrix(element) {
      const values = element.style.transform.split(/\w+\(|\);?/);
      const transform = values[1].split(/,\s?/g).map(parseInt);

      return {
        x: transform[0],
        y: transform[1],
        z: transform[2]
      }
    }, move(side) {
      const element = document.getElementById("carousel");

      const x = this.getMatrix(element).x;

      if(side === 'r' && x === 0) {
        element.style.transform = "translate3d(" + this.getEl()*120 + "px, 0px, 0px)";
      } else if(side === 'l' && x >= this.getEl()*120) {
        element.style.transform = "translate3d(0px, 0px, 0px)";
      } else {
        element.style.transform = "translate3d(" + (side === 'l' ? x + 120 : x-120) + "px, 0px, 0px)";
      }
    }, getEl() {
      if(window.screen.width < 600) return this.companies.length-1;
      if(window.screen.width < 750) return (this.companies.length-3);
      return this.companies.length-4;
    }
  },mounted() {
    const t = this;
    setInterval(function() { t.move('l') }, 4000)
  }
}
</script>

<style>
.zaufaliContainer {
  overflow-x: auto;
  min-width: 600px;
  max-width: 600px;
}

.containerCarousel {
  width: 480px;
  position: relative;
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 750px) {
  .zaufaliContainer {
    overflow-x: auto;
    min-width: 500px;
    max-width: 500px;
  }

  .containerCarousel {
    width: 360px;
  }
}

@media screen and (max-width: 600px) {
  .zaufaliContainer {
    overflow-x: auto;
    min-width: 300px;
    max-width: 300px;
  }

  .containerCarousel {
    width: 120px;
  }
}


.zaufaliBox-control {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
}


.zaufaliBox {
  position: relative;
  padding: 15px;
  border-radius: 25px;
  border: rgba(0,0,0,0.6) solid 2px;
  background-color: #fff;

  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.carouselController {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  align-items: center;
  justify-content: right;
  transition: transform .5s;
  margin-right: 10px;
}

.containerCarousel::-webkit-scrollbar {
  display: none;
}

.zaufaliBox::-webkit-scrollbar {
  display: none;
}

.company {
  display: flex;
  flex-direction: column;
  gap: 15px
}

.company > span {
  font-weight: 500;
  font-family: Poppins,sans-serif;
}

.company > img {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}

.iconLeft {
  position:absolute;
  font-size: 3rem;
  left: 16px;
  cursor: pointer;
}

.iconRight {
  position:absolute;
  font-size: 3rem;
  right: 16px;
  cursor: pointer;
}

.left-shadow {
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  z-index: 4;
  background: linear-gradient(90deg, rgba(255,255,255,0.7) 10%, rgba(255,255,255,0) 100%);
  /*background-color: #000;*/
}

.right-shadow {
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  z-index: 4;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 90%);
  right: 0;
}
</style>
