<template>
  <div>
    <div :class="[{blurBg: true}, {show: active}]"></div>
    <div class="is-flex is-justify-content-center is-align-items-center" style="flex-flow: row wrap;">
      <div class="about-left top">
        <div style="margin-left: auto; position:relative; width: 50%">
          <h1 class="title has-text-weight-bold mb-0" style="text-align: left">Tworzeniem reklam</h1>
          <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Stworzymy dla ciebie reklamę<br>twojego projektu!</h1>
        </div>
      </div>
      <div style="position: relative;">
        <img src="/images/eye/oko1.png"  class="about-image small-img">
        <div class="about-icon small-left-top">
          <font-awesome-icon icon="fa-solid fa-rectangle-ad"
                             @click='open("fa-solid fa-rectangle-ad", "Tworzeniem reklam","Stworzymy dla ciebie reklamę twojego projektu!")'>
          </font-awesome-icon>
        </div>
        <div class="about-icon small-right-top">
          <font-awesome-icon icon="fa-brands fa-discord"
                             @click='open("fa-brands fa-discord", "BOTami Discord","Napiszemy dla ciebie bota przeznaczonego konkretnie pod twój serwer!")'></font-awesome-icon>
        </div>
      </div>
      <div class="about-right top">
        <h1 class="title has-text-weight-bold mb-0">BOTami Discord</h1>
        <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Napiszemy dla ciebie bota przeznaczonego konkretnie<br> pod twój serwer!</h1>
      </div>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center">
      <div class="about-left">
        <div style="margin-left: auto; position:relative; width: 50%">
          <h1 class="title has-text-weight-bold mb-0">Lektorat</h1>
          <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Nagramy świetny lektorat pod twój film!</h1>
        </div>
      </div>
      <div style="position: relative;">
        <img src="/images/eye/oko2.png" class="about-image big-img">
        <div class="about-icon big-left">
          <font-awesome-icon icon="fa-solid fa-microphone-lines"
                             @click='open("fa-solid fa-microphone-lines", "Lektorat","Nagramy świetny lektorat pod twój film!")'></font-awesome-icon>
        </div>
        <div class="about-icon big-right">
          <font-awesome-icon icon="fa-solid fa-palette"
                             @click='open("fa-solid fa-palette", "Tworzeniem Grafiki Cyfrowej","Stworzymy szatę graficzną dla twojego projektu!")'></font-awesome-icon>
        </div>
      </div>
      <div class="about-right">
        <h1 class="title has-text-weight-bold mb-0">Tworzeniem Grafiki Cyfrowej</h1>
        <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Stworzymy szatę graficzną dla twojego projektu!</h1>
      </div>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center" style="margin-left: auto">
      <div class="about-left bottom" style="transform: translateY(40%)">
        <div style="margin-left: auto; position:relative; width: 50%">
          <h1 class="title has-text-weight-bold mb-0">Montażem Wideo</h1>
          <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Zmontujemy film wedle twoich oczekiwań!</h1>
        </div>
      </div>
      <div>
        <div style="position: relative;">
          <img src="/images/eye/oko3.png" class="about-image small-img">
          <div class="about-icon small-left-bottom">
            <font-awesome-icon icon="fa-solid fa-clapperboard"
                               @click='open("fa-solid fa-clapperboard", "Montażem Wideo","Zmontujemy film wedle twoich oczekiwań!")'></font-awesome-icon>
          </div>
          <div class="about-icon small-right-bottom">
            <font-awesome-icon icon="fa-solid fa-globe"
                               @click='open("fa-solid fa-globe", "Projektowaniem Stron WWW","Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!")'></font-awesome-icon>
          </div>
        </div>
      </div>
      <div class="about-right" style="transform: translateY(40%)">
        <h1 class="title has-text-weight-bold mb-0">Projektowaniem Stron WWW</h1>
        <h1 class="subtitle has-text-weight-semibold mt-0 is-6">Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!</h1>
      </div>
    </div>
    <div :class="[{mobileBox: true}, {active: active}]">
      <div class="mobileBoxCard">
        <p class="mobileBoxX" @click="active = false;">x</p>
        <font-awesome-icon :icon="item.icon" style="font-size: 3rem; color: #08adf7;"></font-awesome-icon>
        <h1 class="title has-text-weight-bold mb-0 is-4 has-text-black">{{ item.title }}</h1>
        <h1 class="subtitle is-6 has-text-weight-semibold mt-0 has-text-black" style="font-size: 0.9rem;">{{ item.subtitle }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Eye",
  data() {
    return {
      active: false,
      item: {
        icon: "fa-solid fa-rectangle-ad",
        title: "Tworzeniem reklam",
        subtitle: "Stworzymy dla ciebie reklamę twojego projektu!"
      }
    }
  }, methods: {
    open(icon, title, subtitle) {
      if(window.screen.width > 700) return;
      this.item = {icon, title, subtitle};
      this.active = true;
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 900px) {
  .about-right, .about-left {
    display: none;
  }
}

.about-icon-text {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.about-image {
  max-width: 100%;
  max-height: 100%;
}

.about-icon {
  position:absolute;
  border-radius: 50%;
  background-color: #0da1ff;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: pulse 5s ease infinite;

  width: 64px;
  height: 64px;
}

.about-icon > * {
  font-size: 2.25rem;
  color: white;
}

.small-left-top {
  top:5%;
  left:7%;
}

.small-right-top {
  top:5%;
  right:7%;
}

.small-left-bottom {
  top:38%;
  left:7%;
}

.small-right-bottom {
  top:38%;
  right:7%;
}

.big-left {
  top:23%;
  left:4%;
}

.big-right {
  top:23%;
  right:4%;
}

.small-img {
  width: calc(344px + 16px);
  height: calc(114px + 16px);
}

.big-img {
  width: calc(544px + 16px);
  height: calc(114px + 16px);
}

@media screen and (max-width: 700px) {
  .small-img {
    /*transform: scale(0.6);*/
    width: 210px;
    height: 72px;
  }

  .big-img {
    width: 340px;
    height: 72px;
  }

  .about-icon {
    width: 48px;
    height: 48px;
    cursor: pointer;
  }

  .about-icon > * {
    font-size: 1.75rem;
  }
}

.about-left {
  flex: 1;
  text-align: left;
}

.about-right {
  flex: 1;
  text-align: left
}

.top {
  transform: translateY(-20%);
}

.about-left.bottom {
  transform: translateY(30%);
}

.mobileBox {
  position: absolute;
  z-index: 1001;
  width: 100vw;
  opacity: 0;
  transition: opacity  0.5s;
}

.mobileBoxCard {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  background-image: url('/images/decoration/xd.png');
  background-size: cover;
  padding: 10px;
  position: relative;
  filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.57));
  border-radius: 10px;
}

.mobileBoxX {
  position: absolute;
  right: 15px;
  top: 5px;
  font-weight: 800;
  font-size: 1.25rem;
  cursor: pointer;
}

.mobileBox.active {
  opacity: 1;
}

.blurBg {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  z-index: -1000;
  backdrop-filter: blur(5px) opacity(0);
  transition: backdrop-filter 0.2s;
}

.blurBg.show {
  z-index: 1000;
  backdrop-filter: blur(5px) opacity(1);
}
</style>
