import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'

import "@/assets/css/styles.scss"
import "@/assets/css/decoration.scss"

Vue.use(VueMeta)
Vue.config.productionTip = false

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faMicrophoneLines,
  faRectangleAd,
  faPalette,
  faGlobe,
  faAngleLeft,
  faAngleRight,
  faEnvelope,
  faClapperboard,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faYoutube, faDiscord, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleLeft, faAngleRight, faFacebook, faYoutube, faEnvelope, faDiscord, faTiktok, faInstagram, faClapperboard, faClapperboard, faRectangleAd, faGlobe, faMicrophoneLines, faPalette, faAngleDown);
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
