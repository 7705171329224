<template>
  <div>
    <div class="hero is-fullheight" style="position: relative;">
      <div class="hero-head absolute">
        <Navbar></Navbar>
      </div>
      <div class="hero-body">
        <div class="container is-fluid-hero">
          <div class="columns is-vcentered">
            <div class="column is-8 text-hero">
              <h1 class="title has-text-weight-semibold title1">Tak, zaspokoimy twoje oczekiwania.</h1>
              <h1 class="subtitle primary has-text-weight-bold subtitle1 mb-4">Aż tak bardzo widać?</h1>
              <div class="aboutHero">
                <a class="button-dark">
                  <img src="/images/oko.svg">
                  Coś o nas
                </a>
<!--                <div class="breakk"></div>-->
                <font-awesome-icon icon="fa-brands fa-tiktok" size="2x"  class="heroIcon is-hidden-mobile" />
                <font-awesome-icon icon="fa-brands fa-youtube" size="2x" class="heroIcon is-hidden-mobile" />
                <font-awesome-icon icon="fa-brands fa-facebook" size="2x"  class="heroIcon is-hidden-mobile" />
                <font-awesome-icon icon="fa-brands fa-instagram" size="2x"  class="heroIcon is-hidden-mobile" />
              </div>
            </div>
            <div class="column is-4 is-flex is-justify-content-center is-hidden-mobile">
              <img src="/images/misiu.svg" class="misiu"/>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot" style="animation: bounce 2s infinite;">
        <a href="#about-us" class="scrollIcon">
          <font-awesome-icon icon="fa-solid fa-angle-down" size="3x" />
        </a>
      </div>
      <div class="heroImages" >
        <img src="/images/decoration/hero/blob1.svg" class="blob1">
        <img src="/images/decoration/hero/blob2.svg" class="blob2">
        <img src="/images/decoration/hero/blob3.svg" class="blob3">
        <img src="/images/decoration/hero/triangles.svg" class="triangles">
        <img src="/images/decoration/hero/star1.svg" class="gStar1" >
        <img src="/images/decoration/hero/star1.svg" class="gStar2">
        <img src="/images/decoration/hero/gwiazda.svg" class="star1">
        <img src="/images/decoration/hero/gwiazdaMala.svg" class="star2">
      </div>
    </div>
    <img src="/images/seperator.svg" class="seperator" />

    <!-- REST OF WEBSITE, CONTAINER TO ADD IMAGES -->
    <div style="position: relative; margin-top: -200px; overflow-y: hidden;overflow-x: hidden;">
      <div>
        <img src="/images/decoration/aboutUs/bigWave.svg" class="bigWave">
        <img src="/images/decoration/aboutUs/bigWave2.svg" class="bigWave2">
        <img src="/images/decoration/aboutUs/dots.svg" class="dots">
        <img src="/images/decoration/aboutUs/triangle.svg" class="triangle">
        <img src="/images/decoration/aboutUs/star.svg" class="starr">
        <img src="/images/decoration/aboutUs/star.svg" class="starr2">
        <img src="/images/decoration/aboutUs/circle.svg" class="circle">
        <img src="/images/decoration/aboutUs/triangles.svg" class="triangless">
        <img src="/images/decoration/aboutUs/darkStar.svg" class="darkStar">
        <img src="/images/decoration/aboutUs/darkStar.svg" class="darkSmallStar">
        <img src="/images/decoration/aboutUs/bigStar.svg" class="bigStar">
      </div>
      <div class="" style="padding-top: 18rem; margin-bottom: 15rem" id="about-us">
        <div class="container has-text-centered is-fluid" style="margin-bottom: 4rem;">
          <h1 class="title sectionTitle primary">Czym się zajmujemy?</h1>
        </div>
        <Eye></Eye>
      </div>
      <div class="section" style="margin-bottom: 12rem">
        <div class="container">
          <h1 class="title is-2 primary">Zaufali nam</h1>
          <div class="is-flex is-justify-content-center">
            <Carousel :companies="companies"></Carousel>
          </div>
        </div>
      </div>
      <div class="section" style="margin-bottom: 8rem">
        <div class="container is-max-desktop">
          <h1 class="title sectionTitle">Skontaktuj się z nami!</h1>
          <div class="columns" style="padding-top: 35px">
            <div class="column is-half">
              <font-awesome-icon icon="fa-solid fa-envelope" size="4x" class="primary" style="padding-bottom: 10px" />
              <h1 class="title primary mailText">contact@allmake.pl</h1>
            </div>
            <div class="column is-half">
              <font-awesome-icon icon="fa-brands fa-discord" size="4x" class="primary" style="padding-bottom: 10px" />
              <br>
              <a class="discord-join">Dołącz</a>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Carousel from "@/components/Carousel";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import Eye from "@/components/Eye";

export default {
  name: 'Home',
  components: {Navbar, Footer, Carousel, Eye},
  // components: { VueperSlides, VueperSlide },
  data() {
    return {
      companies: []
        // {icon: "fa-solid fa-rectangle-ad", title: "Tworzeniem reklam", subtitle: "Stworzymy dla ciebie reklamę twojego projektu!"},
        // {icon: "fa-solid fa-microphone-lines", title: "Lektorat", subtitle: "Nagramy świetny lektorat pod twój film!"},
        // {icon: "fa-solid fa-clapperboard", title: "Montażem Wideo", subtitle: "Zmontujemy film wedle twoich oczekiwań!"},
        // {icon: "fa-brands fa-discord", title: "BOTami Discord", subtitle: "Napiszemy dla ciebie bota  przeznaczonego konkretnie pod twój serwer!"},
        // {icon: "fa-solid fa-palette", title: "Tworzeniem Grafiki Cyfrowej", subtitle: "Stworzymy szatę graficzną dla twojego projektu!"},
        // {icon: "fa-solid fa-globe", title: "Projektowaniem Stron WWW", subtitle: "Zaprojektujemy twoją jedyną w swoim rodzaju stronę internetową!"},
    }
  }, created() {
    const companies = process.env.VUE_APP_COMPANIES;

    this.companies = JSON.parse(companies);
  }
}
</script>
